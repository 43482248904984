// 统一封装网络请求方法
import request from '@/utils/request'
//利用request发起ajax请求
// 注册code
export const getRegiter= (code) => request({
    url: `/dire/getHkOpenid?code=${code}`,
    method: "GET"
})
// 是否注册
export const isRegiter= (openid) => request({
    url: `/dire/userSfzc?openid=${openid}`,
    method: "GET"
})
// 用户新增接口
export const userRegiter= (params) => request({
  url: `/dire/isntallUser`,
  method: "GET",
  params
})