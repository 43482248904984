<template>
  <div>
    <van-row>
       <van-col span="24" >
          <van-image
            width="100%" height="200"
            src="https://oss.ninan.vip/ad706021-9dd3-46ce-9ae0-11d6fc8f39bc.jpg"
          />
       </van-col>
   </van-row> 
   <van-row type="flex" justify="center" class="tab">
      <van-col span="12" style="margin-left: 13%;"><van-button type="info" size="middle" :plain="show==false" @click="show=true">非员工</van-button></van-col>
      <van-col span="12"><van-button type="info" size="middle" :plain="show==true" @click="show=false">员工</van-button></van-col>
   </van-row>
   <transition>
      <van-row class="form" v-show="show">
            <van-cell-group>
              <van-field
              v-model="username"
              label="姓  名"
              placeholder="输入姓名"
              clearable
              size="small"
              :label-width="90"
              />
            <van-field
            v-model="phone"
            type="phone"
            label="手 机"
            placeholder="输入手机号"
            :label-width="90"
            />
            
          </van-cell-group>
      
      </van-row>
  </transition>
  <transition>
      <van-row class="form" v-show="!show">
            <van-cell-group>
              <van-field
              v-model="username"
              label="姓  名"
              placeholder="输入姓名"
              clearable
              size="small"
              :label-width="90"
              />
            <van-field
            v-model="phone"
            type="phone"
            label="手 机"
            placeholder="输入手机号"
            :label-width="90"
            />
            
          </van-cell-group>
      
      </van-row>
  </transition>
      <van-notice-bar
        class="notice"
        color="##fefbe0"
        background="#fefbe0"
        left-icon="info"
        scrollable
      >
      提示  &nbsp;: 如匿名可直接点击下方进入。
      </van-notice-bar>
      <van-button  class="button" type="info" size="middle" @click="enter">进入</van-button>
      <div v-if="isLoading">
         <Loading />
      </div>
  </div>
</template>

<script>
import Loading from '../../components/loading.vue';
import { getRegiterAPI ,isRegiterAPI,userRegiterAPI} from "@/api";
export default {
  components: {
    Loading
  },
  data() {
    return {
      show:true,
      isLoading: false,
      username:'',
      phone:'',
      dept: '',
      // showPicker: false,
      // columns: ['运营部', '人事部', '财务部', '行政部', '人力资源部','事业部','其他']
    };
  },
  created() {
    var searchURL = window.location.search;
    searchURL = searchURL.substring(1, searchURL.length);
    var targetPageId = searchURL.split("&")[0].split("=")[1];
    // let targetPageId='08111oFa149AFG0sCPIa1MdyxB011oFT'
    localStorage.setItem('code', targetPageId)
    this.getRegiter()
  },
  methods:{
    async getRegiter(){
      let {data:res}=await getRegiterAPI(localStorage.getItem('code'))
      localStorage.setItem('openid',res.data)
      // let openid=localStorage.getItem('openid')
      // if(res.data){
      //   let {data:result}=await isRegiterAPI(res.data)
      //   this.isLoading=false
      //   if(result.data){
      //     this.$router.push({
      //       path:'/List'
      //     })
      //   }
      // }
    },
    // onConfirm(value) {
    //   this.dept = value;
    //   this.showPicker = false;
    // },
   
   async  enter(){
    if(this.phone){
      if(!(/^1[3456789]\d{9}$/.test(this.phone))){
        this.$toast('手机号格式错误!')
        return
      }
    }
      // name   姓名    photo  手机号   bm  部门   openid
      let {data:res}=await userRegiterAPI({
        name: this.username,
        photo: this.phone,
        openid:localStorage.getItem('openid')
      })
      if(res.code==200){
        if(this.show){
          localStorage.setItem('type','1')
        }else{
          localStorage.setItem('type','2')
        }
       
        let time=setTimeout(()=>{
          this.$toast('恭喜您登录成功!')
          clearTimeout(time)
        },500)
        this.$router.push({
           path:'/List'
        })
      }
     
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.1s;
}
.fade-enter,.fade-leave-to {opacity: 0;}
::v-deep  .tab{
  margin: .5rem 1rem 2rem;
 
}
.van-cell {
  border-bottom: 1px solid lightgray;
}
::v-deep .van-field {
      
      font-size: .4rem;
      color: #141C23;
    }
::v-deep .form{
  margin: .6rem 0.5rem 1rem;

}
::v-deep .van-icon-arrow{
  font-size: .3rem;
}
::v-deep .button{
  display:block;
  margin:0 auto;
  width: 40%;
}
::v-deep van-notice-bar{
 width: 60%;
}
::v-deep .notice{
  font-size: .3rem;
  margin:.3rem 0 1rem;
}
</style>