<template>
    <div class="loading">
      <div class="loading-inners">
          <div class="loading-inner"></div>
          <!-- <div class="txt" v-if="i!=0">倒计时 {{ i }} 秒</div> -->
      </div>
    </div>
  </template>
  <script>
     export default {
      data() {
        return {
          i:5,
          timer:'',
        };
     },
     mounted() {
        this.timer = setInterval(() => {
          this.i --; // 每秒倒计时减一
          if (this.i === 0) {
            clearInterval(this.timer); // 倒计时结束时清除定时器
          }
        }, 1500); // 每秒执行一次
  },
    beforeDestroy() {
      clearInterval(this.timer); // 在组件销毁前清除定时器
    }
     }
  </script>
  <style scoped>
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* opacity: .1;
    background-color: #999; */
    z-index: 9999;
  }
  .loading-inners{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3rem;
    height: 3rem;
    margin-left: -1.5rem;
    margin-top: -1.5rem;
    border-radius: 5%;
    opacity: .8;
    text-align: center;
    background-color: #000;
  }
  .loading-inners .txt{
    text-align: center;
    margin-top: .7rem;
    color: #fff;
    font-size: .3rem;
  }
  .loading-inner {
    /* position: absolute;
    top: 50%;
    left: 50%;
   
    margin-left: -20px;
    margin-top: -20px; */
    width: 1rem;
    height: 1rem; 
    margin-left: 32%;
    margin-top: .9rem;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top-color: #444;
    animation: loading .8s ease-in-out infinite;
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>