<template>
  <div class="main">
    <van-nav-bar title="历史反馈数据"  left-arrow  @click-left="($router.go(-1))"/>
    <div class="content" >
      <div class="top" >
        <van-grid :column-num="1">
            <van-grid-item v-for="(item,index) in list" :key="index">
                <ul class="width100 flex1 flex-align-spacebetween mabott">
                <div class="flex-align">
                    <van-icon name="feedback" :size="55" color="#0283ef"/>
                    <div class="margin-left18 line-height1_5">
                        <li class="font-size6 van-color-text-primary bold title">{{ item.name||'***' }}</li>
                        <li class="margin-top7 font-size2 van-color-text-secondary fontsize">
                            {{ item.cjsj }}
                        </li>
                    </div>
                </div>
                <van-button type="info" size="small" @click="seeDetail(item.id)" v-if="item.zjlsfck=='1'">未查看</van-button>
                <van-button block size="small" @click="seeDetail(item.id)" v-else>已查看</van-button>
                </ul>
            </van-grid-item>
        </van-grid>
        <div class="button">
       <van-button type="info" size="middle" @click="review" v-if="list.length>7||list.length==7">反馈</van-button>
     </div>
    </div>
   </div>
   <div class="buttno" v-if="list.length<7">
      <van-button type="info" size="middle" @click="review">反馈</van-button>
    </div>
  </div>
</template>

<script>
import {getlistAPI,addlist} from "@/api";
export default {
    data(){
        return {
          list: []
        }
    },
    created(){
      this.getlist()
   },
    methods:{
      async getlist(){
        let {data:res}=await getlistAPI(localStorage.getItem('openid'))
        this.list=res.data
      },
      review(){
        // hbnr  反馈内容 tpdz  图片地址   spdz  视频地址
        this.$router.push({
          path:'/Upload'
        })
      },
      seeDetail(val){
        this.$router.push({
          path:'/ListDetail',
          query:{
            id:val
          }
        })
      },
    },
   
}
</script>

<style scoped>
::v-deep .buttno{
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  height: 2rem;
}
::v-deep .buttno .van-button{
 position: fixed;
 left: 31%;
 bottom: 10%;
}
::v-deep .button{
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  height: 2rem;
}
::v-deep .van-grid-item{
  height: 10vh;
  margin-bottom: .2rem;
}
::v-deep .van-grid .van-button{
  margin-top:.3rem ;
  width: 24%;
}
::v-deep .van-button{
  width: 40%;
}
::v-deep .top{
  min-height: 100vh;
}
::v-deep .title{
  font-size: .4rem;
}
::v-deep .fontsize{
 font-size:.35rem;
}
::v-deep .content{
  background-color: #f8f8f8 !important;
  width: 100%;

 
}
</style>