<template>
  <div id="app">
    <!-- 显示路由 -->
    <router-view></router-view>
  </div>
</template>

<script>
 

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style lang="less">
 
</style>
