<template>
  <div>
        <van-nav-bar
        title="详情"
        left-arrow
        @click-left="($router.go(-1))"
        />
        <van-row style="margin-top: 2%;">
            <van-col span="1" >
                <van-icon name="calendar" size="45" color="#4095e5"/>
            </van-col>
            <van-col span="21" style="font-size: .38rem;margin-left: .8rem;">
                2023年对你有什么遗憾? 2024年你想怎么做?你有什么要告诉我的?
            </van-col>
       </van-row>
        <van-cell-group style="margin-top: .3rem;">
            <van-field
                type="textarea"
                v-model="list.hbnr"
                rows="5"
                autosize
            />
        </van-cell-group>
        <van-row class="row" v-if="list.tpdz">
        <van-col span="15">相关图片<span class="span">(点击预览)</span></van-col>
        </van-row>
        <van-row class="pic">
        <van-col span="8"
           v-for="(item,index) in list.tpdz" 
           :key="index">
            <img :src="item"  @click="sceneImg(list.tpdz,index)" alt="">
        </van-col>
        </van-row>
        <van-row class="row" v-if="list.spdz">
        <van-col span="15">相关视频<span class="span">(点击预览)</span></van-col>
        </van-row>
        <van-row class="rows" v-if="list.spdz">
        <van-col span="15" class="vide">
            <video
             :src="list.spdz" 
             controls="controls"
             style="width:100%; height: 80%"
            >
        </video>
        </van-col>
        </van-row>
        <van-row class="title">
           <van-col span="24" class="txts">
            回复内容
            </van-col>
        </van-row>
        <van-row class="cont">
           <van-col span="24">
            {{ list.hfnr}}
            </van-col>
        </van-row>
         <van-button type="info"  block @click="($router.go(-1))" size="middle">返回</van-button>
  </div>
</template>

<script>
import {getdetailAPI} from "@/api";
import { ImagePreview } from 'vant'
export default {
    data(){
        return{
            list:{},
            message2:'',
            message:'1243y6ggg5ujh76yik7yhjol,',
            imageLists: [require('@/assets/index.jpg'), require('@/assets/logo.png'),require('@/assets/logo.png')]
        }
    },
    created(){
      this.getlist()
   },
    methods:{
       async  getlist(){
          let {data:res}=await getdetailAPI(this.$route.query.id)
          this.list=res.data[0]
        },
        // 图片预览
        sceneImg (images, index) {
            ImagePreview({
                images: images, // 需要预览的图片 URL 数组
                showIndex: true, // 是否显示页码
                loop: false, // 是否开启循环播放
                startPosition: index // 图片预览起始位置索引
            })
        },
    },
}
</script >

<style scoped>
.title{
height: .8rem;
font-size: .4rem;
border-bottom: .06rem solid #f4f4f4;
}
.cont{
 padding: .1rem 0;
min-height: 100%;
font-size: .38rem;
line-height: 1.5;
text-indent: .5rem;
border-bottom: .06rem solid #f4f4f4;
}
.txts{
margin-left: .4rem;
}
::v-deep .van-button{
    margin: 1.3rem auto 1rem;
    width: 50vw;
}
::v-deep .spec .van-field{
border-top: .06rem solid #fff;
}
::v-deep .van-field__control{
    background-color: #fff;
    color: #111!important;
}
::v-deep .rows video{
    margin: .3rem 0rem .4rem;
   
}
::v-deep .vide{
 padding:  0 .2rem;
 width: 100%;
 height: 4.5rem;
}
img{
    margin: .3rem 0.1rem .4rem;
    width: 2rem;
    height: 3rem;
}
::v-deep .row{
    padding: .2rem .35rem;
    font-size: .4rem;
    border-bottom: .06rem solid #f4f4f4;

}
.span{
    margin-left:.2rem ;
    display: inline;
    font-size: .38rem;
    color: #999999;
}
::v-deep .van-field{
    margin: .2rem .0;
    border-top: .06rem solid #f4f4f4;
    font-size: .39rem;
}
</style>

</style>