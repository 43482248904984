<template>
  <div>
    <van-nav-bar
        title="详情"
        left-arrow
        @click-left="($router.go(-1))"
    />
    <van-row>
       <van-col span="24" >
          <van-image
            width="100%" height="200"
            src="https://oss.ninan.vip/fbaa481d-43a2-4925-980d-d8e9def743d1.jpg"
        />
       </van-col>
   </van-row>
    <van-cell-group>
        <van-field
            type="textarea"
            v-model="message"
            placeholder="请输入内容"
            rows="6"
            autosize
            maxlength="1000"
            show-word-limit
        />
    </van-cell-group>
        <van-uploader
        v-model="fileList"
        multiple
        :max-count="3"
        progress
        :progress-interval="5000"
        title="相关图片上传"
        description="(最多三张大小不超过5M)"
        :after-read="afterRead"
        upload-icon="plus"
        > 
    </van-uploader>
        <van-uploader
        v-model="videoList"
        accept="video/*"
        progress
        :progress-interval="500"
        :max-count="1"
        title="视频上传"
        description="(大小不超过50M)"
        :after-read="Read"
        :before-delete="afterDelete"
        :preview-full-image="false"
        :preview-image="false"
        />
    <div>
        <div v-if="show==true" style="margin-left: .2rem;" @click="play" class="box">
            <van-icon name="video" size="80" />
            <div class="icon">
                <van-icon name="close" size="20" @click.stop="Delete" />
            </div>
        </div>
     
    </div>
   
      <!-- <van-dialog v-model="showvide" title="视频查看" >
            <video :src="url" autoplay  width='100%' height='50%'></video>
       </van-dialog> -->
       <van-button type="info"   block @click="submit" size="middle">提交</van-button>
       <div v-if="isLoading">
         <Loading />
      </div>
  </div>
</template>

<script>
import { Dialog } from 'vant-green';
import {addResultAPI,uploadResultAPI,getlistAPI} from  "@/api";
import Loading from '../../components/loading.vue';
export default {
    components: {
    Loading
  },
    data(){
        return{
            isLoading: false,
            showvide:false,
            show:false,
            message:'',
            fileList:[],
            url:'',
            videoList:[]
        }
    },
    methods:{
        async submit(){
            if(!this.message){
                this.$toast('内容不能为空!')
                return
            }
            let array_pic=[]
            let video_url=''
            this.fileList.map(item=>{
                array_pic.push(item.content)
            })
            this.videoList.map(item=>{
                video_url=item.content
            })
            Dialog.confirm({
                title: '提示',
                message: '确定提交反馈内容吗？',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
                })
                .then(async() => {
                    // on confirm
                    this.isLoading=true
                    let {data:res}=await addResultAPI({
                    hbnr: this.message,
                    tpdz: array_pic,
                    spdz: video_url,
                    lb:localStorage.getItem('type'),
                    openid: localStorage.getItem('openid')
                    })
                    if(res.code==200){
                        this.loading=true
                        this.isLoading=true
                        this.$toast('新增成功!')
                    }
                    this.$router.go(-1)
                        })
                .catch(() => {
                    // on cancel
               });
        },
        play(){
            this.showvide=true
            this.url=this.videoList[0].content
        },
        Delete(){
            this.show=false
            this.videoList=[]
        },
        async  Read(files, detail){
            if (files[0].file.size > 50 * 1024 * 1024) {
                this.videoList=[]
                this.$toast('文件大小不能超过50M');
                return false
            }else{
            //     files.forEach(element => {
            //     setTimeout(() => {
            //     element.status = null;
            //     }, 3000);
            //   });
                this.show=true
            }
            
           
        },
        afterDelete(files){
            this.videoList=[]
        },
        async afterRead(files, detail) {
            files.forEach(element => {
                setTimeout(() => {
                element.status = null;
                }, 1500);
            });
      }
    },
    created:{

    }
}
</script>

<style  scoped>
::v-deep .van-uploader__preview-delete{
    height: .48rem !important;
    line-height: 0.01rem !important;
}
::v-deep .van-button{
    margin: 1.3rem auto 1rem;
    width: 50vw;
}
.view{
    width: 100%;
    height: 10rem;
    background-color: red;
}
::v-deep .van-field{
    margin: .2rem .0;
    border-top: .06rem solid #f4f4f4;
    font-size: .35rem;
}
::v-deep .box{
position: relative;
}
::v-deep .icon{
  position: absolute;
  top: 1%;
  left: 20%;
}
</style>
