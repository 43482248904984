import request from '../utils/request'

// //  获取列表
export const getlist = (openid) => request({
  url: `/dire/getFkGr?openid=${openid}`,
  method: "GET"
})

// // 获取详情
export const getdetail = (id) => request({
  url: `/dire/getXqMap?id=${id}`,
  method: "GET"
})
// 新增列表数据
export const addlist = (id) => request({
    url: `/dire/installFk?id=${id}`,
    method: "GET"
  })