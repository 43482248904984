// 让所有接口请求，统一由index.js负责导出，供外部使用
// 导入home页面的推荐歌单接口请求的模块
import {getRegiter,isRegiter,userRegiter} from './home'
import {getlist,getdetail,addlist} from './List'
import {addResult,uploadResult} from './upload'

// 导入搜索页面所有的请求方法
// import {hotSearch,searchResult} from './search'
// // 导入播放页面的请求
// import {getSongById ,getLyricById} from './Play'
export const getRegiterAPI = getRegiter // 请求推荐歌单的方法导出
export const isRegiterAPI =isRegiter
export const userRegiterAPI=userRegiter
export const getlistAPI=getlist
export const getdetailAPI=getdetail
export const addlistAPI =addlist
export const addResultAPI=addResult
export const uploadResultAPI=uploadResult