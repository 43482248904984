// 这是一个网络请求 二次封装 设置基础地址
import axios from 'axios'
// axios.defaults.baseURL="http://192.168.1.223:8080/admin/api"
axios.defaults.baseURL="https://www.ninan.vip/admin/api"
// 向外导出这个
// 请求拦截
// axios.interceptors.request.use(res => {
//   return Promise.resolve(res)
// }, error => {
//   return Promise.reject(error)
// })

// // 响应拦截
// axios.interceptors.response.use(res => {
//   if (res.status === 200) {
//     return Promise.resolve(res)
//   } else {
//     MessageBox('提示', '服务器出问题了，请重新进入试试')
//   }
// }, error => {
//   MessageBox('提示', '服务器出问题了，请重新进入试试')
//   return Promise.reject(error)
// })
export default axios