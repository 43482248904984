<template>
  <div>
    <van-nav-bar
      title="总经理直通车"
    />
  <div class="main">
      <router-view></router-view>
  </div>
    <!-- <van-tabbar v-model="active" route>
      <van-tabbar-item icon="home-o" replace to="/layout/home"
        >总经理直通车</van-tabbar-item
      >
      <van-tabbar-item icon="search" replace to="/layout/search"
        >搜索</van-tabbar-item
      >
    </van-tabbar> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      activeTitle:this.$route.meta.title
    }
  },
  // 开启事件监听，当路由切换的时候，头部标题也一同切换
  watch:{
  $route(){
     this. activeTitle=this.$route.meta.title
    }
  }
};
</script>

<style scoped>
.main {
  /* padding-top: 46px; */
}
</style>