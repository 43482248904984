import Vue from 'vue'
import App from './App.vue'
import '@/mobile/flexible.js'
import '@/styles/reset.css'
// import { recommendMusicAPI } from '@/api/index'
// 导入路由,可以省略index.js
import router from "@/router"
// 在src/main.js进行全局引入
import VantGreen from 'vant-green';
import 'vant-green/lib/index.css';
import "video.js/dist/video-js.css";

Vue.use(VantGreen);
Vue.config.productionTip = false
// async function myFn(){
//   const res = await recommendMusicAPI({limit: 6});
//   console.log(res);
// }
// myFn();

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
