import request from "@/utils/request"
// 新增列表
export const addResult = data => request({
    url: '/dire/installFk',
    method: "POST",
    data
})
// 上传图片视频

export const uploadResult = data => request({
    url: '/dire/installWj',
    method: "POST",
    data
})